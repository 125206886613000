body {
  margin: 0;
  padding: 0;
}

#map {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.id-editor-button {
  line-height: 29px;
}
/*# sourceMappingURL=index.b8b859d8.css.map */
